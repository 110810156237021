import { useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, NavbarBrand } from "reactstrap"
import { Profile } from "../../constant/logo"
import { hamburgerMenu, home, logoutDuotone, profileDuotone, settingDuotone } from '../../constant/icons';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../components/auth';
import ReactSelect from "react-select";
import { confirmBox } from '../../components/toast';
import { useApp } from '../../components/app';


export const Header = () => {
    const auth = useAuth()
    const app = useApp()

    const location = useLocation()
    let applicationMode: string | undefined | null = location.pathname.split('/')[1]
    if (!['odoo', "QBO", "xero", "zoho", "qbo"].includes(applicationMode)) {
        applicationMode = undefined
    }

    if (auth.user?.role == "User") {
        if (applicationMode === "qbo") {
            applicationMode = "QBO"
        } else {
            applicationMode = auth?.user?.loginType
        }
    }


    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    return <header className="header">
        <div className='left-panel'>
            <Link to={''} title='Collapse sidebar' className='hamburger-btn'>
                {hamburgerMenu.icon}
            </Link>
            <h5>
                {app.conversation?.name || "Dashboard"}
            </h5>
        </div>
        <div className='right-panel'>
            <FormGroup className='app-select'>
                {
                    <ReactSelect
                        isDisabled={applicationMode ? true : false}
                        menuPortalTarget={document.body}
                        menuPlacement="auto"
                        menuPosition={'fixed'}
                        placeholder={<div>Select app</div>}
                        value={app.getAppDropDownList()?.find(x => x.value === app.currentApp?.appCode)}
                        onChange={async (newValue: any) => {
                            if (!app.conversation || await confirmBox("If you switch the application, you will be automatically redirected to the Company page")) {
                                app.onCurrentAppChange(newValue?.value)
                                document.documentElement.style.setProperty('--condition', '3');
                            }
                        }}
                        options={applicationMode ? app.getAppDropDownList()?.filter(x => x.value == applicationMode) : app.getAppDropDownList()}
                    />
                }
            </FormGroup>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} className='profile-main'>
                <DropdownToggle color='link'>
                    <span>
                        {/* {Profile} */}
                        AD
                    </span>
                    <h6>
                        {auth.user?.name}
                        <small>{ auth.user?.isMaster?"Admin":auth.user?.role}</small>
                    </h6>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => auth.logOut()}>
                        <span>{logoutDuotone.icon}</span>
                        <p>Log Out
                            <small></small>
                        </p>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    </header>
}

import PageLayout from "../layout/page";
import {
  arrowLeft,
  clickIcon,
  deleteIcon,
  detailInfo,
  downloadIcon,
  plus,
} from "../../constant/icons";
import { Button, Progress } from "reactstrap";
import { useEffect, useState } from "react";
import { useApp } from "../../components/app";
import { useLocation, useNavigate } from "react-router-dom";
import { JobDetails } from "./jobDetails";
import { DataView } from "../../components/table/table";
import { useApi } from "../../helper/apiHook";
import { columns } from "./columns";
import { useAddUpdate } from "../../helper/addUpdateHook";
import { AddUpdateJob } from "./addUpdate/addUpdate";
import { useLoaderHelper } from "../../helper/loaderHelper";
import { get, post } from "../../helper/api";
import { message } from "../../components/toast";
import { not_connected } from "../company/message";
import { ReckonJobList } from "./reckon/reckonJobList";
import Swal from "sweetalert2";

const JobList = () => {
  const app = useApp();
  const location = useLocation();
  let navigate = useNavigate();
  if (!app.conversation && location?.state?.data) {
    app.updateConversation(location?.state?.data);
  }
  if (!app.conversation && !location?.state?.data) {
    navigate("/");
  }
  const loading = useLoaderHelper();
  const [isJobDetailsOpen, setJobDetailsOpen] = useState<boolean>(false);
  const addUpdate = useAddUpdate();
  const api = useApi("job",true,{ tenantId: app.conversation?.tenant?.[0]?.id || 0 },
    {
      revalidateOnFocus:addUpdate.viewAddUpdate || isJobDetailsOpen ? undefined : true,
      refreshInterval:addUpdate.viewAddUpdate || isJobDetailsOpen ? undefined : 3000,
      revalidateIfStale:addUpdate.viewAddUpdate || isJobDetailsOpen ? undefined : true,
      revalidateOnReconnect:addUpdate.viewAddUpdate || isJobDetailsOpen ? undefined : true,
    },
    undefined,
    false);

  const actionClickButton = async (data: any) => {
    if (data.clickType == "details") {
      setOpenJobId(data);
      jobDetailsToggle();
    } else if (data.clickType == "remove") {
      const result = await post(
        "/jobDelete",
        { id: data.id },
        undefined,
        loading
      );
      // if (result?.status) {

      // }
    }
  };

  const actionClick = actionClickButton;

  //---Job Details Open Close

  const [openJobId, setOpenJobId] = useState(0);
  const jobDetailsToggle = () => setJobDetailsOpen(!isJobDetailsOpen);
  //---

  //-------------------Current Selected App

  //-------------------

  const onRowDoubleClick = (e: any, event: any) => {
    setOpenJobId(event.childProps.rowData);
    jobDetailsToggle();
  };
  const getRefreshData = () => {
    api.mutate();
  };
  useEffect(()=>{
      if(app.currentApp?.appCode=="xero"&&app.conversation?.tenant?.[0]?.isConnected){

        Swal.fire({
          title: 'We are checking your company connection with Xero. Kindly wait...',
          text: 'Please wait...',
          icon: 'info',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        get("/xero/refreshConnectionStatus",{tenant:app.conversation?.tenant?.[0]?.id}).then((result)=>{
             if("connected"==result){
              Swal.fire({
                title: 'Your company is connected!',
                text: 'Connection with Xero was successful.',
                icon: 'success',
                timer: 2000, // Auto close after 5 seconds
                showConfirmButton: false,
              });
             }else{
              Swal.fire({
                title: 'Your company is disconnected!',
                text: 'Connection with Xero failed.',
                icon: 'error',
              });
              // window.location.reload()
              navigate("/")
                          
             }
        }).catch(()=>{

        })
      }
  },[])


  return addUpdate.viewAddUpdate ? (
    app.currentApp?.appCode=="rdo"?
    <ReckonJobList/>
    :<AddUpdateJob
      closeAddUpdate={addUpdate.closeAddEditFrom}
      api={api}
      value={addUpdate.currentValue}
    />
  ) : (
    <PageLayout
      title={
        <>
          <Button
            color="link"
            onClick={() => navigate("/")}
            className="btn-back"
          >
            {arrowLeft.icon}{" "}
          </Button>
          Function
        </>
      }
      item={
        app.conversation?.tenant?.[0]?.isConnected?<Button 
        
        color="primary" onClick={() => addUpdate.openAddEditFrom(false)}>
          {plus.icon}Add New Function
        </Button>:<h5 style={{color:"red"}}>{not_connected}</h5>
      }
      View={
        <>
          {isJobDetailsOpen && (
            <JobDetails
              refreshData={getRefreshData}
              isJobDetailsOpen={isJobDetailsOpen}
              jobDetailsToggle={jobDetailsToggle}
              openJobId={openJobId}
            />
          )}
          <DataView
            columns={columns({ api, actionClick })}
            rows={api.data}
            rowCount={api.rowCount}
            currentPage={api.currentPage}
            limit={api.limit}
            onRowDoubleClick={onRowDoubleClick}
            updatePagination={api.updatePagination}
          />
          <div className="doubleclick_note">
            <p>
              <span>{clickIcon.icon}</span>View Function double-click on row
            </p>
          </div>
        </>
      }
    />
  );
};
export { JobList };

import { Table, useTable, useTableInstance } from "ka-table";
import {
  ActionType,
  DataType,
  EditingMode,
  FilteringMode,
  PagingPosition,
  SortingMode,
} from "ka-table/enums";
import "./style.scss";
import { AttributeTableData, ChildComponents, Column } from "ka-table/models";
import { Button, FormGroup, Input } from "reactstrap";
import { loadData, updatePageSize } from "ka-table/actionCreators";
import PagingPages from "./pagingPages";
import { message } from "../toast";
import { ICellTextProps, IDataRowProps } from "ka-table/props";
import { useMemo, useState } from "react";
import { close, searchIcon } from "../../constant/icons";
import { kaPropsUtils } from "ka-table/utils";

export declare class TableColumn extends Column {
  component?: any;
  valueConvert?: any;
  hideHeaderFilter?: Boolean;
  callBack?: (rowValue: any) => void;
}
export function generateUniqueId() {
  const timestamp = new Date().getTime(); // Get the current timestamp
  const random = Math.random() * 1000000; // Generate a random number
  const uniqueId = `${timestamp}${random}`;

  return `Table${uniqueId}`;
}

const SelectionCell: React.FC<ICellTextProps> = ({
  rowKeyValue,
  isSelectedRow,
  selectedRows,
}) => {
  const table = useTableInstance();
  console.log("isSelectedRow", isSelectedRow);
  return (
    <input
      type="checkbox"
      checked={isSelectedRow}
      onChange={(event: any) => {
        if (event.nativeEvent.shiftKey) {
          table.selectRowsRange(rowKeyValue, [...selectedRows].pop());
        } else if (event.target.checked) {
          table.selectRow(rowKeyValue);
        } else {
          table.deselectRow(rowKeyValue);
        }
      }}
    />
  );
};

const SelectionHeader = () => {
  const table = useTableInstance();
  const areAllRowsSelected = kaPropsUtils.areAllFilteredRowsSelected(
    table.props
  );

  return (
    <input
      type="checkbox"
      checked={areAllRowsSelected}
      onChange={(event) => {
        if (event.currentTarget.checked) {
          table.selectAllFilteredRows(); // also available: selectAllVisibleRows(), selectAllRows()
        } else {
          table.deselectAllFilteredRows(); // also available: deselectAllVisibleRows(), deselectAllRows()
        }
      }}
    />
  );
};

const GetComponents = (
  columnsList?: TableColumn[]
): ChildComponents | undefined => {
  if (columnsList && columnsList.length > 0) {
    const componentList = columnsList.filter(
      (x) => x.component || x.valueConvert || x.hideHeaderFilter
    );
    if (componentList && componentList.length > 0) {
      return {
        headFilterButton: {
          content(props) {
            try {
              if ((props.column as TableColumn).hideHeaderFilter) {
                return <></>;
              }
            } catch {}
          },
        },
        cellText: {
          content: (props) => {
            if (props.column.key === "selection-cell") {
              return <SelectionCell {...props} />;
            }
            const columnFind = componentList.filter(
              (x) => x.key === props.column.key
            )[0];
            if (columnFind) {
              if (columnFind.component) {
                return <columnFind.component {...props} />;
              } else if (columnFind.valueConvert) {
                return columnFind.valueConvert(
                  props.rowData[props.column.key],
                  props.rowData
                );
              }
            }
          },
        },
        headCell: {
          content: (props) => {
            if (props.column.key === "selection-cell") {
              return <SelectionHeader />;
            }
          },
        },
      };
    }
  }
  return undefined;
};
let tableComponents: ChildComponents = {
  table: {
    elementAttributes: () => ({
      className: "table table-striped table-hover",
    }),
  },

  noDataRow: {
    content: () => "No Data Found",
  },
  pagingIndex: {
    elementAttributes: ({ isActive }) => ({
      className: `page-item ${isActive ? "active" : ""}`,
    }),
    content: ({ text, isActive }) => (
      <a
        className={`page-link ${isActive ? "active" : ""}`}
        href="javascript:void(0)"
      >
        {text}
      </a>
    ),
  },
  pagingPages: {
    elementAttributes: () => ({
      className: "pagination",
    }),
    content(props) {
      return <PagingPages {...props} />;
    },
  },
  pagingSizes: {
    content: (props) => {
      return (
        <FormGroup>
          <Input
            type="select"
            value={props.pageSize}
            onChange={(e) => {
              props.dispatch(updatePageSize(Number(e.target.value)));
            }}
          >
            {props.pageSizes?.map((size) => (
              <option>{size}</option>
            ))}
          </Input>
        </FormGroup>
      );
    },
  },
};
const addTableChildComponents = (props: any): ChildComponents => {
  const { onRowDoubleClick, hideRowHeader } = props;
  let result = {};
  if (onRowDoubleClick) {
    result = {
      dataRow: {
        elementAttributes: () => ({
          onDoubleClick: (
            event: React.MouseEvent<HTMLElement, MouseEvent>,
            extendedEvent: AttributeTableData<IDataRowProps>
          ) => {
            onRowDoubleClick(event, extendedEvent);
          },
        }),
      },
    };
  } else {
    result = { dataRow: undefined };
  }
  result = {
    ...result,
    tableHead: {
      elementAttributes: () => ({
        className: "thead-dark",
      }),
      content: (propsContent: any) => (hideRowHeader ? <></> : undefined),
    },
  };
  return result;
};
const DataView = (props: any) => {
  const {
    html,
    columns,
    rows,
    currentPage,
    onSelectionChange,
    rowCount,
    limit,
    striped = false,
    isLoading = false,
    hideRowHeader = false,
    keyId = "id",
  } = props;
  const [searchText, setSearchText] = useState("");
  const components = GetComponents(columns);
  const paging = props.updatePagination
    ? {
        paging: {
          enabled: true,
          pageIndex: currentPage ? Number(currentPage) : 0,
          pagesCount: rowCount ? Math.ceil(rowCount / limit) : 1,
          pageSize: limit,
          pageSizes: [10, 20, 50, 100, 500, 800, 1000],
          position: PagingPosition.Bottom,
        },
      }
    : {
        paging: {
          enabled: true,
          pageIndex: 0,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100, 500, 800, 1000],
          position: PagingPosition.Bottom,
        },
      };

  const table = useTable({
    onDispatch: async (action) => {
      if (
        props.onSelectionChange &&
        (action.type === ActionType.SelectRow ||
          action.type === ActionType.SelectAllFilteredRows ||
          action.type === ActionType.DeselectAllFilteredRows ||
          action.type === ActionType.DeselectRow)
      ) {
        if (action.type === ActionType.SelectRow || action.type === ActionType.DeselectRow) {
          const rowKeyValue = action.rowKeyValue
          console.log("Row Key Value:", rowKeyValue);
          onSelectionChange(action.type, rowKeyValue);
        } else if (action.type === ActionType.SelectAllFilteredRows || action.type === ActionType.DeselectAllFilteredRows) {
          // Handle bulk selection actions
          const filteredRows: any = table.props.selectedRows || [];
          if (action.type === ActionType.SelectAllFilteredRows) {
            console.log("filteredRows", filteredRows)
            onSelectionChange(action.type, filteredRows);
          } else if (action.type === ActionType.DeselectAllFilteredRows) {
            onSelectionChange(action.type, []);
          }
        }
      }
      if (props.updatePagination) {
        if (action.type === ActionType.UpdatePageIndex) {
          props.updatePagination().updateCurrentPageNo(action.pageIndex);
        } else if (action.type === ActionType.UpdatePageSize) {
          props.updatePagination().updatePageLimit(action.pageSize);
        }
      }
    },
  });
  if (components) {
    tableComponents = { ...tableComponents, ...components };
  }
  tableComponents = { ...tableComponents, ...addTableChildComponents(props) };
  const tableText = useMemo(() => {
    return (
      <Table
        key={generateUniqueId()}
        table={table}
        {...paging}
        childComponents={tableComponents}
        columns={columns}
        data={rows}
        selectedRows={[]}
        filteringMode={FilteringMode.HeaderFilter}
        rowKeyField={"tableId"} //keyId
        searchText={searchText}
        sortingMode={SortingMode.Single}
      />
    );
  }, [rows, columns, searchText]);
  return (
    <div className="data-table data-table-wrapper">
      <div className="d-flex justify-content-between mb-3">
        <div className=""></div>
        <div className="search_box">
          <Button color="link" className="p-0">
            {searchIcon.icon}
          </Button>

          <input
            name="searchbox"
            placeholder="Search.."
            type="search"
            value={searchText}
            onChange={(event) => {
              setSearchText(event.currentTarget.value);
            }}
            className="top-element"
          />
        </div>
      </div>
      {tableText}
    </div>
  );
};
export { DataView };

import { Button, FormGroup, Input } from "reactstrap";
import { confirmBox } from "../toast";

import { Link } from "react-router-dom";
import { connectIcon, connectToQboIcon, connectToXeroIcon, disconnectToQboIcon, disconnectToXeroIcon } from "../../constant/icons";
import { useAuth } from "../auth";
import { GetAppId } from "../app";
import recoknConnect from '../../assets/images/recoknConnect-one.png'
import recoknDisConnect from '../../assets/images/reckon_one_Disconnect.png'
import xeroConnect from '../../assets/images/connect-blue.svg'
import XeroDisConnect from '../../assets/images/disconnect-blue.svg'


import { generateUniqueId } from "../table/table";

require('./switchButton.css')


const SwitchButton = (props: any) => {
    const loginType = GetAppId()
    const { disabled, column, rowData, value, message } = props;
    const isConnected = rowData.tenant?.[0]?.isConnected;

    const handleClick = async () => {
        const shouldProceed = isConnected ? await confirmBox(message) : true;
        if (shouldProceed && column.callBack) {
            column.callBack(rowData);
        }
    };
    return (<>
        {
            loginType == "rdo" ? <>
                {
                    <Link key={generateUniqueId()} to={{}} title={isConnected ? "Disconnect from Reckon One" : "Connect to Reckon One"} onClick={handleClick}>
                        {isConnected ? <img className="img-fluid" alt="background" src={recoknDisConnect} /> : <img className="img-fluid" alt="background" src={recoknConnect} />}
                    </Link>
                }
            </> :
                loginType == "xero" ?
                    <>
                        {
                            <Link key={generateUniqueId()} to={{}} title={isConnected ? "Disconnect from XERO" : "Connect to XERO"} onClick={handleClick}>
                                {isConnected ? <img className="img-fluid" alt="background" src={XeroDisConnect} /> : <img className="img-fluid" alt="background" src={xeroConnect} />}
                            </Link>
                        }
                    </> : loginType == "QBO" ? <>
                        <Link key={generateUniqueId()} to={{}} title={isConnected ? "Disconnect from QuickBooks" : "Connect to QuickBooks"} onClick={handleClick}>
                            {isConnected ? disconnectToQboIcon.icon : connectToQboIcon.icon}
                        </Link>
                    </> :
                        <Button key={generateUniqueId()} color="success" className={rowData.tenant?.[0]?.isConnected ? "disconnect_btn" : "connect_btn"} onClick={
                            async () => {
                                const response = rowData.tenant?.[0]?.isConnected ? await confirmBox(message) : !rowData.tenant?.[0]?.isConnected;
                                if (response && column.callBack) {
                                    column.callBack(rowData)
                                }
                            }
                        } >
                            {connectIcon.icon}<span>{rowData.tenant?.[0]?.isConnected ? "Disconnect" : "connect"}</span>
                        </Button>

        }
    </>)
};

export { SwitchButton };